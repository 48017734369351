module.exports = [{
      plugin: require('/Users/tds/sandbox/steel-windows2/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-3V47KSMTBC","head":false,"anonymize":true},
    },{
      plugin: require('/Users/tds/sandbox/steel-windows2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148385110-1"},
    },{
      plugin: require('/Users/tds/sandbox/steel-windows2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tds/sandbox/steel-windows2/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Comfortaa"]}},
    },{
      plugin: require('/Users/tds/sandbox/steel-windows2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
