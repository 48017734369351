import styled from 'styled-components'

export const Button = styled.button`
    margin: 20px auto;
    max-width: 300px;
    border: 1px solid red;
    padding: 20px;
    display: block;
    color: red;
    cursor: pointer;
    border-radius: 4px;
    ${props => props.block ? 'width: 100%;':'width: 100%;'}

    ${props => props.locked ?
        'border: 1px solid #eee; color: #eee;'
        :
        'border: 1px solid red; color: red;'
    }
`