/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Header from "./header"
import "./layout.css"
import {FirebaseContext, useAuth} from './Firebase'
import Footer from "../components/footer"

const Layout = ({ children }) => {

  const {user, firebase, loading } = useAuth();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <FirebaseContext.Provider value={{user, firebase, loading}}>


        <Header siteTitle={data.site.siteMetadata.title} />
        <Wrapper>
          <main>{children}</main> 
        </Wrapper>
        <Footer></Footer>

    </FirebaseContext.Provider >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


const Wrapper = styled.div`
  min-height:calc(100vh - 200px);
  margin: 0 auto,
  maxWidth: '100%',
  padding: 0px,
  paddingTop: 0,
`

export default Layout;