import React , {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button , Input } from './';
import moment from 'moment'


const CommentForm = styled.form`
    display: flex;
    
`

const CommentListItem = styled.div`
    border-bottom: 1px solid #ddd;
    padding: #666;
    padding: 15px 0 0 0;

    >h5{
        font-size: 80%;
        color: #666;
    }

    ${Input}{
        font-size: 16px;
    }
`


const ProductComments = ({firebase, productId}) => {

    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');

    function handlePostCommitSubmit(e){
        e.preventDefault();
        firebase.postComment({
            text: commentText,
            productId
        })
        setCommentText('')
    
    }
   

useEffect(()=>{
    const unsubscribe = firebase.subscribeToProductsComments({
        productId,
        onSnapshot: (snapshot) => {
            const snapshotComments = [];
            snapshot.forEach(doc =>{
                snapshotComments.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setComments(snapshotComments);
        }
    })

    return () =>{
        if (unsubscribe){
            unsubscribe();
        }
    }

},[])
    
    return(
        <div>
            {firebase.auth.currentUser !== null &&
            <CommentForm onSubmit={handlePostCommitSubmit}>
                <Input type='textarea' value={commentText} onChange={e => {
                    e.persist();
                    setCommentText(e.target.value)
                }}></Input>
                <Button block type="submit"> Add Comment</Button>
            </CommentForm>
            }

            { comments.map(comment=>(
                <CommentListItem key={comment.id}>
                    <span>{comment.text}</span>
                    <h5>{comment.username} - {moment(comment.dateCreated.toDate()).format('DD.MM.YYYY')}</h5>
        
                </CommentListItem>

            )) }
        </div>
    )
}

export default ProductComments;