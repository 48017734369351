import React, {useState, useContext, useEffect} from "react"
import styled from 'styled-components';
import Architect from "../images/architect.svg"
import {PopupContext} from '../components/header'
import {FirebaseContext} from '../components/Firebase';

import mimeTypes from 'mime-types';

import { Input, Button, Form , TextField} from '../components/common'

let fileReader;
if(typeof window !== 'undefined'){
  fileReader = new FileReader();
}

const Quote = () => {

    const {firebase} = useContext(FirebaseContext);
    const [name, updateName] = useState("");
    const [email, updateEmail] = useState("");
    const [content, updateContent] = useState('');
    const [filePath, setFile] = useState('');
    const [success, setSuccess] = useState(false);
    const [fileValidation, setFileValidation] = useState(false);
    const  navigation = useContext(PopupContext);
    let isMounted = true;

    useEffect(() => {
        fileReader.addEventListener('load', () => {

            const mimeType = fileReader.result.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1];

            if(mimeTypes.contentType(mimeType) === 'image/png'){
                setFileValidation(true);
            }else if(mimeTypes.contentType(mimeType) === 'image/jpeg'){
                setFileValidation(true);
            }else if(mimeTypes.contentType(mimeType) === 'application/pdf'){
                setFileValidation(true);
            }else{
                setFileValidation(false);
            }
            setFile(fileReader.result);
        })
      }, []);

    return(
        <div>   
            <OpenBtn onClick={e=>navigation.toggleQuote(!navigation.quote)}  style={{ right: navigation.quote === false && "0px"}}>
                <img src={Architect} alt='architect'/> 
                <p>Quick<br/> Quote</p>
            </OpenBtn>
            <QuoteWrapper style={{ height: (navigation.quote === true && navigation.menu === false ) && "100%"} }>
            { navigation.quote === true && <span className="closebtn" onClick={(e) => {
                
                navigation.toggleQuote(!navigation.quote);
                setSuccess(false);
                updateName('');
                updateEmail('');
                updateContent('');
                setFile('');
                setFileValidation(false)
                 }}>&times;</span> }
            { success === true ? <div style={{width: '300px', margin: '150px auto'}}><h3 style={{color: 'green'}}>Form sent sucessfully</h3><p style={{}}>We will get in touch soon</p></div> :  
            <Form onSubmit={(e) => {
                e.preventDefault();
                firebase.createQuote({
                    name, email, content, filePath
                }).then(() => {
                    if(isMounted) {
                    setSuccess(true)
                    }
                })
                }}>
                <Input type="text" required placeholder="Name" value={name} onChange={(e) => updateName(e.target.value)}/>
                <Input type="email" required placeholder="Email" value={email} onChange={(e) => updateEmail(e.target.value)}/>
                Provide as many details as you can.
                <TextField value={content}
                   onChange={(e) => updateContent(e.target.value)}
                   />
                    <strong>File (pdf, jpg, png)</strong>
                    {   (filePath !== '' && fileValidation === false) &&
                        <span>This file type is not supported</span>
                    }

                    <Input 
                        required
                        style={{backgroundColor: (filePath !== '' && fileValidation === false)? 'red':'white'}}
                        type="file" onChange={e => {
                            e.persist();
                            setSuccess(false);
                            fileReader.readAsDataURL(e.target.files[0])
                            }} />
                    
                    { (filePath !== '' && fileValidation === false) ? <Button disabled  locked block >Send</Button>  :  <Button block >Send</Button>  }
                </Form> }
            </QuoteWrapper>
        </div>
    )
}

const OpenBtn = styled.div`
    font-size: 30px;
    cursor: pointer; 
    position: fixed;
    padding: 20px 30px;
    right: -120px;
    z-index: 1000;
    top: 150px;
    background: #D82227;
    text-align: center;
    transition: 0.3s;

    img{
        width: 75px;
    }

    p{
        color: white;
        font-size: 18px;
    }

    @media only screen and (max-width: 768px) {
        margin: 0px;
        padding: 20px 20px;

        img{
            width: 55px;
        }
        p{
            color: white;
            font-size: 14px;
        }
    }
`

const QuoteWrapper = styled.div`
    width: 100%;
    height: 0%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.9);
    overflow-x: hidden;
    transition: 0.3s;
    display: flex;

    .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
        color: #D82227;
    }


    @media only screen and (max-width: 768px) {

        .closebtn {
            right: 20px;
        }

    }
`



export default Quote;
