import styled from 'styled-components'

export const InputFile = styled.input`      
 
        height: 0;
        overflow: hidden;
        width: 0;
        display: none;
        margin: 0 auto;
    
    + label {
        white: #f15d22;
        border: none;
        color: #666;
        cursor: pointer;
        display: inline-block;
        border-bottom: 1px solid #D82227;
        width: 300px;
        margin-bottom: 1rem;
        outline: none;
        padding: 10px 0 0 0 ;
    }
`