import firebaseConfig from "./config";
import axios from 'axios';

class Firebase {
  constructor(app) {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);

      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
    }
  }


  getUserProfile({userId, onSnapshot}){
    return this.db.collection('publicProfiles')
      .where('userId', '==', userId)
      .limit(1)
      .onSnapshot(onSnapshot)
  }

  async createQuote({name, email, content, filePath}){

   // console.log(name, email, content, filePath);

    const createQuoteCallable = this.functions.httpsCallable('createQuote');
    return createQuoteCallable({
      name, email, content, filePath
    });
  }


  async createBrand({brandName, brandText}){
    const createBrandCallable = this.functions.httpsCallable('createBrand');
    return createBrandCallable({
      brandName, brandText
    });
  }

  async createAuthor({authorName}){
    const createAuthorCallable = this.functions.httpsCallable('createAuthor');
    return createAuthorCallable({
      authorName
    });
  }

  async getAuthors(){
    return this.db.collection('authors').get();
  }

  async getBrands(){
    return this.db.collection('brands').get();
  }


  async createProduct({productName, brandId, mainImage, content}){
    const createProductCallable = this.functions.httpsCallable('createProduct');
    return createProductCallable({
      productName,
      brandId,
      mainImage,
      content
    })
  }

  async createBook({bookName, authorId, bookCover, summary}){
    const createBookCallable = this.functions.httpsCallable('createBook');
    return createBookCallable({
      bookName,
      authorId,
      bookCover,
      summary
    })
  }



  async register({email, password, username}) {
    await this.auth.createUserWithEmailAndPassword(email, password);
      const createProfileCallable = this.functions.httpsCallable('createPublicProfile');
      return createProfileCallable({
        username
      })
  }

  subscribeToProductsComments({productId, onSnapshot}){
    
    const productReff = this.db.collection('products').doc(productId)
   
    return this.db.collection('comments')
    .where('product', '==', productReff)
    .orderBy('dateCreated', 'desc')
    .onSnapshot(onSnapshot)
  }



  async postComment({text, productId}){

    const postCommentCollable = this.functions.httpsCallable('productComments')
    return postCommentCollable({
      text, productId
    })
  }

  async login({email, password}) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    await this.auth.signOut();
  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;
