
import React ,{useContext}from "react"
import styled from 'styled-components'
import { navigate } from "gatsby"
import {FirebaseContext} from './Firebase'


const Footer = ({ children }) => {

    const {firebase, user} = useContext(FirebaseContext);

    const logOutHandler = () =>{
        firebase.logout().then(()=>navigate('/login'))
    }

    //  console.log(user)

  return (
        <FooterWtapper>
          
            <h5>Steel Windows</h5>
            <p>
                Unit 251,
                Blanchardstown Corporate Park 2,
                Ballycoolin, Dublin 15,
                Co. Dublin
                D15 W62P<br/>
                Email:<a href="mailto:info@tdsltd.ie"> info@tdsltd.ie</a> | Tel: <a href="tel:+35318665831">+353 1 866 5831</a><br/>
                All rights reserved <a href="https://www.tdsltd.ie"> TDSLtd</a>   © {new Date().getFullYear()},
                { !!user && user.email &&
                    <span>
                      <br/>Hello: {user.username || user.email} <LogotLink onClick={logOutHandler}> Log out </LogotLink>
                    </span>
                }
               
            </p>


        </FooterWtapper>
        )
}

const FooterWtapper = styled.footer`
  padding: 50px;
  background: #44454A;
  color: white;
  font-size: .7em;
`

const LogotLink = styled.a`

color: red;

`

export default Footer
