import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState, createContext} from "react"
import styled from "styled-components"
import Logo from "../images/logo.svg"
import TopNav from "../components/topNavigation"
import Quote from "../components/quote"


export const PopupContext = createContext();

const Header = (props,{ siteTitle }) => {
const [menu, toggleNavigation] = useState(false);
const [quote, toggleQuote] = useState(false);

return(
  <PopupContext.Provider value={{menu, quote, toggleQuote, toggleNavigation}}>
    <HeaderWrapper>
      <div>
        <Link to="/" className="logo" >
          <img src={Logo} alt="logo"/>
        </Link>
        <TopNav/>
        <Quote  />
      </div>
    </HeaderWrapper>
  </PopupContext.Provider>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const HeaderWrapper = styled.header`
  background: white;
  border-top: 3px solid #D82227;
  width: 100%;

  a{
    float:left
  }

  a.logo>img{
    height: 55px; 
    padding: 0;
    margin: 0px 50px;
    width: iniial;
  }

  :after{
    clear: both;
    content: '';
    display: block;
  }
  @media only screen and (max-width: 768px) {
  
    a.logo>img{
      height: 15vw; 
      padding: 0;
      margin: 0px 20px;;
    }

   
  }
`
export default Header