import React, { useContext } from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import {PopupContext} from '../components/header'

const Nav = () => {

    const  navigation = useContext(PopupContext);

    const closeNav = () =>{
        navigation.toggleNavigation(false);
        navigation.toggleQuote(!navigation.quote);
    }

    return(
        <div>
        {navigation.menu === false && <OpenBtn onClick={(e) => {navigation.toggleNavigation(true); navigation.toggleQuote(!navigation.quote)}}>&#9776; {navigation.menu} </OpenBtn>}
        
        <Menu style={{ width: navigation.menu === true && "100%"} }>
        { navigation.menu === true && <span className="closebtn" onClick={(e) => {closeNav()}} >&times;</span> }
        <MenuWrapper  >
            <Link to="/" onClick={(e) => {closeNav()}}>Home</Link>
            <Link to="/brands" onClick={(e) => {closeNav()}}>Brands</Link>
            {/* <Link to="/crittall-windows" onClick={(e) => {closeNav()}}>Crittall Windows</Link> */}
            <Link to="/steel-windows" onClick={(e) => {closeNav()}}>Steel Windows</Link>
            <Link to="/fire-rated-windows" onClick={(e) => {closeNav()}}>Fire Rated Windows</Link>
            <Link to="/articles" onClick={(e) => {closeNav()}}>Articles</Link>
            <div style={{borderTop: '1px solid white', height: '1px', margin: '20px auto', width: '200px'}}></div>
            <a href="mailto:info@tdsltd.ie">Email Us</a>
            <a href="tel:+35318665831">Call Us Now </a>
        </MenuWrapper>
        </Menu>
    </div>
    )
}

const OpenBtn = styled.span`
    font-size: 30px;
    cursor: pointer; 
    float: right;
    margin: 20px 50px;
    @media only screen and (max-width: 768px) {
        margin: 20px;

    }
`

const Menu = styled.div`
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: rgb(216, 34, 39);
    background-color: rgba(216, 34, 39, 0.9);
    overflow-x: hidden;
    transition: 0.3s;
  

    .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
        color: white;
    }


    @media only screen and (max-width: 768px) {

        .closebtn {
            right: 20px;
        }

    }



`

const MenuWrapper = styled.div`
    position: relative;
  
    width: 100%;
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    a{
        padding: .5em;
        text-decoration: none;
        font-size: 36px;
        color: white;
        display: block;
        transition: 0.3s;
    }

    @media only screen and (max-width: 768px) {
        a{
            font-size: 22px;
            padding: .6em;
        }
    }

    
    
`   

export default Nav;
