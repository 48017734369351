const firebaseConfig = {
    apiKey: "AIzaSyA_PSHlZDuTlEba6CstPQc8vnd5TwCF2vg",
    authDomain: "steel-windows.firebaseapp.com",
    databaseURL: "https://steel-windows.firebaseio.com",
    projectId: "steel-windows",
    storageBucket: "steel-windows.appspot.com",
    messagingSenderId: "646132239221",
    appId: "1:646132239221:web:de92351594d0c1b3b0613c"
  };

  export default firebaseConfig;