// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/tds/sandbox/steel-windows2/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-template-js": () => import("/Users/tds/sandbox/steel-windows2/src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-brand-template-js": () => import("/Users/tds/sandbox/steel-windows2/src/templates/brandTemplate.js" /* webpackChunkName: "component---src-templates-brand-template-js" */),
  "component---src-templates-product-template-js": () => import("/Users/tds/sandbox/steel-windows2/src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-pages-404-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-author-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/add-author.js" /* webpackChunkName: "component---src-pages-add-author-js" */),
  "component---src-pages-add-brand-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/add-brand.js" /* webpackChunkName: "component---src-pages-add-brand-js" */),
  "component---src-pages-add-product-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/add-product.js" /* webpackChunkName: "component---src-pages-add-product-js" */),
  "component---src-pages-articles-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-brands-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-fire-rated-windows-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/fire-rated-windows.js" /* webpackChunkName: "component---src-pages-fire-rated-windows-js" */),
  "component---src-pages-index-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-steel-windows-js": () => import("/Users/tds/sandbox/steel-windows2/src/pages/steel-windows.js" /* webpackChunkName: "component---src-pages-steel-windows-js" */)
}

