import styled from 'styled-components';

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
//export const TextField = styled(ReactQuill)`

export const TextField = styled.textarea` 
    max-width: 300px;
    width: 100%;
    padding:  12px;
    margin: 20px auto;
    display: block;
    
    box-shadow: none;
    border: 1px solid red;
    
    &:focus{
        border: 1px solid red  
    }

    @media only screen and (max-width: 768px) {
        padding: 15px;
        font-size: 16px;
        margin: 20px 0;
        border: 1px solid #D82227;
    }
`