import styled from 'styled-components'

export const ErrorMessage = styled.span`
 color: red;
 text-align: center;
 font-size: 13px;
 margin : 10px;

`

